<template>
  <div class="service-directory container">
    <!-- 主体 -->
    <div class="main">
      <!-- left -->
      <article class="listpage-left-title">
        <div class="line1">
          <div>GUIDE</div>
          <div>服务指南</div>
        </div>
        <div class="line2">
          <div :class="item.sortIndex == type? 'active': '' "
            v-for="(item, index) in typeList" :key="index" 
            @click="renderList(item.sortIndex)">{{item.moduleName}}</div>
        </div>
      </article>

      <!-- 右侧列表 -->
      <ul class="right-list">
        <div class="search-con" style="margin-top: 20px">
          <el-input placeholder="请输入标题名称" v-model="searchtitle" />
          <el-button type="primary" @click="currentPage = 1; renderList();">查询</el-button>
          <el-button @click="searchtitle = null; currentPage = 1; renderList();">重置</el-button>
        </div>
        <template v-if="rightList.length > 0">
        <li v-for="v in rightList" :key="v.id">
          <div
            class="title text-overflow-ellipsis"
            @click="$router.push({ name:'服务指南详情',query:{id:v.id} })"
          >
            <i class="iconfont icon-dian"></i>
            {{ v.title }}
          </div>
          <div class="date">{{ v.modifyTime|filterTime }}</div>
        </li>
        <el-pagination
          :current-page="currentPage"
          :page-size="pageSize"
          :total="total"
          @current-change="sizeChange"
          :page-sizes="[3, 5, 10, 20]"
          layout="prev, pager, next"
          background
          prev-text="上一页"
          next-text="下一页"
        ></el-pagination>
        </template>

        <div class="emptydom" v-else>
          <img src="@/assets/images/empty/none_pic@2x.png" />
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import serviceDirectoryApi from "@/api/serviceDirectory";

export default {
  created() {
    console.log(this.typeList)
    this.type = this.$route.query.type; //获取type
    this.renderList(); //根据type去获取对应的列表
  },
  data() {
    return {
      type: 1,
      rightList: [],
      searchtitle: null,

      // 分页相关参数
      currentPage: 1,
      pageSize: 10,
      total: 99
    };
  },
  computed: {
    // 防止从vuex获取过来的nav为空数组(即导航接口获取的数据还没到)，所以把typeList放在计算属性里面
    typeList() {
      if (this.$store.state.nav.length) {
        return this.$store.state.nav[6].children;
      } else {
        return this.$store.state.nav;
      }
    }
  },
  filters: {
    filterTime(val) {
      return moment(val).format("YYYY-MM-DD");
    }
  },
  methods: {
    // 分页相关函数
    currentChange(val) {
      this.currentPage = val;
      this.renderList();
    },
    sizeChange(val) {
      this.pageSize = val;
      this.renderList();
    },

    // 渲染相关函数
    async renderList(index = this.type) {
      this.type = index
      let { code, data, message } = await serviceDirectoryApi.findList({
        module: 5, //所属模块(1:通知公告、2:最新动态、3:政策法规、4:下载专区、5:服务指南)
        serviceType: this.type, //服务指南模块（1办事指南；2办事流程；3管理规定；4数字证书办理）
        page: this.currentPage,
        size: this.pageSize,
        sort: "desc",
        sortFiled: "modify_time",
        title: this.searchtitle,
      });
      this.rightList = data.records;
      this.total = data.total;
    }
  },
  watch: {
    "$route.query"(newVal, oldVal) {
      this.type = newVal.type; //重新赋值type
      this.renderList(); //根据type重新获取对应列表
    }
  }
};
</script>

<style lang="less" scoped>
.service-directory {
  .listpage-left-title {
    .line1 {
      background-image: url(~@/assets/images/service/ic_titel_fwzn@2x.png);
    }
  }
  // 主体
  .main {
    display: flex;
    // 右侧列表
    .right-list {
      flex: 1;
      li {
        display: flex;
        justify-content: space-between;
        line-height: 70px;
        border-bottom: 1px dashed #dcdcdc;
        font-size: 18px;
        color: rgba(32, 32, 30, 1);
        .title {
          width: 640px;
          cursor: pointer;
        }
        .icon-dian {
          color: rgba(243, 110, 49, 1);
        }
        .date {
          font-size: 14px;
          color: #979797;
        }
      }
      // 分页
      .el-pagination {
        padding-top: 20px;
      }
    }
  }
}
</style>